<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded && (configId==='167679' || configId==='167682') &&  (department == null || department.id == null)">
        Некорректная ссылка! ЦЗН не найден.
      </div>
      <div v-else-if="apiLoaded">
        <header>
          <div>
            <div class="wrap">
              <div id="logo"><img alt="" src="../assets/logo.svg"></div>
            </div>
          </div>
          <div>
            <div class="wrap">
              <h1>{{ anket != null ? anket.title : "Анкетирование завершено" }}</h1>
              <h2>{{ anket != null ? anket.subTitle : "" }}</h2>
              <br>
              <h2> {{ department ? department.name : '' }} </h2>
            </div>
          </div>
        </header>

        <main v-if="!inactiveAnket">
          <div class="wrap">
            <div v-if="stepIndex===0 && !showResultComponent" class="twoColumns">
              <div v-if="!anket.anonymous">
                <div v-if="departmentNotFound">
                  ЦЗН, отмеченный в ссылке, не найден. Пожалуйста, укажите Ваш ЦЗН. Для этого сначала укажите регион,
                  в котором находится Ваш ЦЗН.
                  <v-autocomplete
                      label="Регион ЦЗН"
                      :items="departmentsListByRegions"
                      item-text="name"
                      item-value="departments"
                      dense
                      rounded
                      filled
                      v-model="departmentsOfSelectedRegion"
                  ></v-autocomplete>
                  <v-autocomplete
                      label="Ваш ЦЗН"
                      :items="departmentsOfSelectedRegion"
                      item-text="name"
                      item-value="id"
                      dense
                      rounded
                      filled
                      v-model="department.id"
                  ></v-autocomplete>
                </div>
                <div v-else>
                  <v-text-field
                      v-model="department.name"
                      dense
                      filled
                      readonly
                      label="Ваш ЦЗН"
                      rounded
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field v-model="anketEditInfo.email" dense filled
                                :label="anket.isEmailOptional ? 'Электронная почта (необязательное поле)' : 'Электронная почта'"
                                rounded></v-text-field>
                </div>
                <div>
                  <v-text-field v-model="anketEditInfo.regnumber" dense filled
                                :label="(anket.isEmployer ? 'Уникальный номер работодателя' : 'Уникальный номер соискателя')
                                + (anket.isNumberOptional ? ' (необязательное поле)' : '')"
                                rounded></v-text-field>
                  <!--              <a href="#">Где найти уникальный номер?</a>-->
                </div>
<!--                <div>-->
<!--                  <v-autocomplete v-model="anketEditInfo.cznName" :items="cznUsersList" dense-->
<!--                                  filled-->
<!--                                  readonly-->
<!--                                  item-text="name"-->
<!--                                  item-value="userName"-->
<!--                                  :label="anket.anonymous ? 'Ваш ЦЗН (необязательное поле)' : 'Ваш ЦЗН'"-->
<!--                                  rounded-->
<!--                  ></v-autocomplete>-->
<!--                </div>-->
              </div>
              <div :style="anket.anonymous? 'width:100%' : ''">
                <div class="anket-description">{{ anket.description }}</div>
                <p>{{ anket.subDescription }}</p>
              </div>
            </div>
<!--            <v-row v-else-if="!anket.anonymous" class="top-row">-->
<!--              <v-col cols="12" lg="4">-->
<!--                <v-text-field :value="anketEditInfo.email" dense filled label="Электронная почта" readonly-->
<!--                              rounded></v-text-field>-->
<!--              </v-col>-->
<!--              <v-col cols="12" lg="4">-->
<!--                <v-text-field :value="anketEditInfo.regnumber" dense filled label="Уникальный номер соискателя"-->
<!--                              readonly rounded></v-text-field>-->
<!--              </v-col>-->
<!--              <v-col cols="12" lg="4">-->
<!--                <v-autocomplete :items="cznUsersList" :value="anketEditInfo.cznName" dense filled-->
<!--                                item-text="name"-->
<!--                                item-value="userName"-->
<!--                                label="Ваш ЦЗН"-->
<!--                                readonly-->
<!--                                rounded-->
<!--                ></v-autocomplete>-->
<!--              </v-col>-->
<!--            </v-row>-->
            <div class="steps">
              <div
                  v-for="(step, stepIteratorIndex) in anket.steps"
                  :key="'step'+stepIteratorIndex"
                  :class="stepIndex===stepIteratorIndex ? 'selected' : ''"
                  id="stepIndexDiv"
              >Шаг {{ stepIteratorIndex + 1 }}
              </div>
            </div>
            <ResultComponent v-if="showResultComponent" :show-result="!anket.noShowResult"
                             :result-text="tGetAnketResult()"></ResultComponent>
            <StepComponent v-else :step="anket.steps[stepIndex]" :step-index="stepIndex"
                           :no-numbers="anket.noNumbers" :has-errors="errorText!=null"></StepComponent>
            <div class="form-buttons">
              <v-btn x-large @click="nextStep()" v-if="!showResultComponent" :disabled="loading">
                {{ stepIndex === (anket.steps.length - 1) ? 'Отправить' : 'Продолжить' }}
              </v-btn>
            </div>
            <div class="error">{{ errorText }}</div>
          </div>
        </main>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.anket-description {
  white-space: pre-line;
  font-size: 130%;
  font-weight: 450;
}

</style>


<script>
import StepComponent from "../components/StepComponent";
import ResultComponent from "../components/ResultComponent";
import {
  validateStep,
  extractAnswers,
  getAnketResult,
  validateOnlyMaxLengthInStep,
  //getExcelAnswersStr
} from "../modules/questions";
import api from "../modules/api";

export default {
  components: {ResultComponent, StepComponent},
  props: {},
  methods: {
    nextStep() {
      let valstat = this.validate()
      if (valstat !== true) {
        this.errorText = valstat
        return
      }
      this.errorText = null

      if (this.stepIndex !== (this.anket.steps.length - 1))
        this.stepIndex++
      else //последний раздел - получить результат
        this.saveAndShowResult()
      document.getElementById("stepIndexDiv").scrollIntoView()
    },

    validate() {
      if (this.stepIndex === 0) {
        if (!this.anket.isEmailOptional) {
          if (!this.anket.anonymous && (this.anketEditInfo.email == null || this.anketEditInfo.email.length === 0))
            return "Укажите адрес электронной почты"
          if (!this.anket.anonymous && !/.+@.+\..+/.test(this.anketEditInfo.email)) {
            return "Введите корректный адрес электронной почты"
          }
        }
        if (!this.anket.isNumberOptional) {
          if (!this.anket.anonymous && (this.anketEditInfo.regnumber == null || this.anketEditInfo.regnumber.length === 0))
            return "Укажите уникальный номер соискателя"
          // if (!this.anket.anonymous && (this.anketEditInfo.cznName == null || this.anketEditInfo.cznName.length === 0))
          //   return "Укажите ЦЗН"
        }
      }
      let r
      if (this.configId === '1336')
        r = validateOnlyMaxLengthInStep(this.anket, this.stepIndex)
      else
        r = validateStep(this.anket, this.stepIndex)
      if (r !== true) {
        return r
      }
      return true
    },

    async serverLog() {
      // await fetch("https://app.ppatest.nisse.ru/output",
      //     {
      //       method: 'POST', headers: {
      //         'Content-Type': 'application/json'
      //       }, body: JSON.stringify({str: JSON.stringify(this.anket)})
      //     })
    },

    async saveAndShowResult() {
      try {
        this.loading = true
        let query = {
          configId: this.configId,
          email: this.anketEditInfo.email,
          regnumber: this.anketEditInfo.regnumber,
          // cznName: this.anketEditInfo.cznName,
          cznName: this.department.id,
          answers: extractAnswers(this.anket)
        }
        //console.log(query)
        const postHeaders = new Headers()
        postHeaders.append('Accept', 'application/json')
        postHeaders.append('Content-Type', 'application/json')
        try {
          this.serverLog()
        } catch (e) {
          console.log('serverLog', e)
        }
        // console.log(query)
        let req = await fetch(this.$backendUrl + "/ankets/saveAnketFormData",
            {method: 'POST', headers: postHeaders, body: JSON.stringify(query)})
        if (!req.ok) {
          this.errorText = "Не удалось передать результаты заполнения анкеты на сервер"
          alert('Ошибка!')
        } else {
          this.showResultComponent = true
        }
      } catch (e) {
        console.log(e)
        this.errorText = "Не удалось передать результаты заполнения анкеты на сервер"
        alert('Ошибка!')
      } finally {
        this.loading = false
      }
    },

    async loadAnketConfig() {
      let req = await fetch(this.$backendUrl + "/ankets/getConfig?id=" + this.configId)
      if (req.ok) {
        let res = await req.json()
        if (!res.active) {
          this.inactiveAnket = true
          return
        }
        this.anket = JSON.parse(res.anketJson)
      } else {
        this.errorText("Не удается подключиться к серверу")
      }
    },

    tGetAnketResult() {
      return getAnketResult(this.anket)
    },

    async getDepartmentInfo() {
      await this.loadDepartmentsList();
      if (this.depFromQuery) {
        for (let dep of this.departmentList) {
          if (this.depFromQuery.id == dep.id) {
            this.department = dep;
            this.departmentNotFound = false;
          }
        }
      } else {
        this.departmentNotFound = true;
      }
    },

    async loadDepartmentsList() {
      let req = await api.get("/department/getList");
      if (req.ok) {
        this.departmentList = req.payload;
      } else {
        alert("Ошибка получения данных")
        return
      }
      //Сортировка департаментов по регионам
      let map = new Map(),
          departmentsByRegion;
      for (let dep of this.departmentList) {
        if (!map.has(dep.region.code)) {
          map.set(dep.region.code, {
            name: dep.region.name,
            code: dep.region.code,
            year: dep.region.year,
            departments: [dep]
          })
        } else {
          map.get(dep.region.code).departments.push(dep)
        }
      }
      departmentsByRegion = Array.from(map.values());
      departmentsByRegion.sort((a, b) => (a.name > b.name ? 1 : -1));
      for (let dep of departmentsByRegion) {
        dep.departments.sort((a, b) => (a.name > b.name ? 1 : -1))
      }
      this.departmentsListByRegions = departmentsByRegion;
    },

  },

  async beforeMount() {
    // console.log(this.$route.query)
    this.configId = this.$route.params.id;
    this.depFromQuery = this.$route.query;
    console.log('this.depFromQuery', this.depFromQuery)
    await this.loadAnketConfig()
    await this.getDepartmentInfo();
    //console.log(getExcelAnswersStr(this.anket, '[[0,9,0,2,0,9,0,9,0,9,0,0,9,0,9,4,"дргуо",10,10,10,10,"1212122112"],[5,"Разное",3,[0,4]]]').join('\n'))
    if (this.$route.params.id === "1336")
      this.anket.noNumbers = true
    this.apiLoaded = true
  },


  data() {
    return {
      inactiveAnket: false,
      cznUsersList: [],
      showResultComponent: false,
      anketEditInfo: {
        regnumber: null,
        email: null,
        cznName: null
      },
      errorText: null,
      anket: null,
      stepIndex: 0,
      depFromQuery: null,
      departmentList: [],
      departmentNotFound: true,
      department: {id: null},
      departmentsListByRegions: [],
      departmentsOfSelectedRegion: null,
      apiLoaded: false,
      loading: false,
    };
  },

};
</script>
